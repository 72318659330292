import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  getWorkDays,
  getDayWithExactHour,
  compareDates,
  parseToUserTimeZone,
  getLocalStorageItem,
  setLocalStorageItem,
} from '@shared/utils'
import { ScheduleDay } from './components/schedule-day'
import { ScheduleHour } from './components/schedule-hour'

export const scheduleAgendaCheckedClasses = `bg-primary text-white font-bold`
export const scheduleAgendaUncheckedClasses = `bg-vcx-dark-white`

interface ScheduleProps {
  schedules: any[]
  noDigital: boolean
  watchValue: any
  fieldName: string
  containerClassName?: string
  dayContainerClassName?: string
  dayClassName?: string
  hourContainerClassName?: string
  hourClassName?: string
  dayInnerContainerClassName?: string
  dayNumberClassName?: string
  modifyDate: Date | string | null
  previousDate?: string | Date | null | undefined
  setModifyDate?: (date: Date | string | null) => void
  isMod: boolean
}

interface ScheduleSlot {
  id: string
  slotDate: string
  slotEndDate: string
  slotType: boolean
}

export const ScheduleGrid: React.FC<ScheduleProps> = observer(({
  schedules,
  noDigital,
  watchValue,
  fieldName,
  containerClassName = '',
  dayContainerClassName = '',
  dayClassName = '',
  hourContainerClassName = '',
  hourClassName = '',
  dayInnerContainerClassName = '',
  dayNumberClassName = '',
  previousDate = localStorage.getItem('dateSelected'),
  modifyDate,
  setModifyDate = () => {},
  isMod
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | string | null>(modifyDate)
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [selectedSlots, setSelectedSlots] = useState<Record<string, boolean>>({})

  
  const groupSlotsByDate = (slots: ScheduleSlot[]) => {
    return slots.reduce<Record<string, ScheduleSlot[]>>((groupedSlots, slot) => {
      const date = new Date(slot.slotDate).toISOString().split('T')[0]
      if (!groupedSlots[date]) {
        groupedSlots[date] = []
      }
      groupedSlots[date].push(slot)
    
      return groupedSlots
    }, {}) 
  }

  const groupedSlots = groupSlotsByDate(schedules)

  const handleModifyDate = (date: Date | string | null, slotId: string) => {
    resetSlots()
    setSelectedSlots((prev) => ({
      ...prev,
      [slotId]: !prev[slotId],
    }))

    setSelectedDate(date)
    setModifyDate(date)
  }

  const resetSlots = () => {
    const resetSelectedSlots: Record<string, boolean> = {}
    Object.keys(selectedSlots).forEach(key => {
      resetSelectedSlots[key] = false
    })
    setSelectedSlots(resetSelectedSlots)
  }
    
  return (
    <div className={`grid grid-cols-7 gap-2 xl:gap-6 ${containerClassName}`}>
      {Object.keys(groupedSlots).map((date, groupIndex) => (
        <div 
          key={date}
          className={`flex flex-col ${dayContainerClassName}`}
          data-testid={`schedule-agenda-day-${groupIndex}`}>
          <ScheduleDay
            dayClassName={`flex place-content-center ${dayClassName}`}
            containerClassName={`w-full flex gap-2 justify-center items-end uppercase font-semibold pb-4 ${dayInnerContainerClassName}`}
            dayNumberClassName={`font-bold text-2xl ${dayNumberClassName}`}
            day={date}
          />
          <div className={`flex flex-col gap-2 ${hourContainerClassName}`}>
            {groupedSlots[date].map((item, index) => (
              <ScheduleHour
                noDigital={noDigital}
                watchValue={previousDate}
                className={`inline-flex justify-center items-center border rounded w-full text-sm ${hourClassName}`}
                disabled={item.slotType}
                isAvailable={item.slotType}
                isSelected={selectedSlots[item.id] || false}
                fieldName={fieldName}
                key={`${item.slotDate}.${index}`}
                slotStart={item.slotDate}
                slotEnd={item.slotEndDate}
                modifyDate={modifyDate}
                setModifyDate={() => handleModifyDate(item.slotDate, item.id)}
                isMod={isMod}
                data-testid={index}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
})


