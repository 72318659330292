import {
  GenericAnalysisModel,
  GenericAnalysisResults,
  GenericAnalysisTest,
} from '@sr/data-access'
import {
  TestWithScript,
  actionsCampaign,
  actionsAnalysis,
} from '@sr/routes/common'
import { flow, getEnv, types } from 'mobx-state-tree'
import { GA_TEST_TYPE_NAME, GA_TEST_TYPE_SCRIPT, VALIDATE_ALL } from './enums'
import { sortByKey } from '@shared/utils'
import { pdf } from '@react-pdf/renderer'
import { ReportPdf, ReportTypeName, loadNs } from '@shared/ui'
import { ModalType } from './hooks'
import { BaseRouteState, PersonModel } from '@shared/data-access'
import i18next from 'i18next'

export const AnalysisRouteState = BaseRouteState.named('AnalysisRouteState')
  .props({
    modalType: types.maybeNull(types.string),
    personId: types.string,
    testId: types.string,
    testList: types.maybeNull(
      types.array(types.reference(GenericAnalysisTest)),
    ),
    testSelectedForValidate: types.maybeNull(types.string),
    testResults: types.maybeNull(types.reference(GenericAnalysisResults)),
    test: types.maybeNull(types.reference(GenericAnalysisModel)),
    person: types.maybeNull(types.reference(PersonModel)),
    reportType: types.number,
  })
  .views((self) => ({
    get testListOrdered() {
      return sortByKey({ array: self.testList?.slice(), key: 'testOrder' })
    },
    get typeList() {
      return self.testListOrdered?.map((test) => ({
        ...test,
        name: GA_TEST_TYPE_NAME[test.testType],
        script: GA_TEST_TYPE_SCRIPT[test.testType],
        scriptProps: {
          province: self.person?.region,
          town: self.person?.locality,
        },
        autoValidate:
          test.id === self.testSelectedForValidate ||
          self.testSelectedForValidate === VALIDATE_ALL,
        Component: TestWithScript,
      }))
    },
    get resultsParsed() {
      if (!self.testResults?.parsed) return {}

      return {
        results: {
          initialAnalysisList: self.testResults?.parsed,
          testDate: self.testResults?.genericAnalysisDate,
          modificationDate: self.testResults?.genericAnalysisResultsDate,
        },
      }
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('AnalysisCampaignsRouteState')
      try {
        yield self.loadTest()
        yield self.loadPerson()
        yield self.loadTestsList({ onError: self.onError })
      } finally {
        self.removeLoading('AnalysisCampaignsRouteState')
      }
    }),
    sendReport: flow(function* sendReport() {
      self.setLoading('sendReport')
      try {
        yield loadNs({
          i18n: i18next,
          ns: [
            'initial-report',
            `report-${ReportTypeName[self.test?.genericAnalysisType]}`,
          ],
        })
        if (!self.testResults) {
          yield self.generateTestResults({ onError: self.onError })
        }

        const myPdf = pdf(
          <ReportPdf type={self.reportType} results={self.resultsParsed} />,
        )

        const blob = yield myPdf.toBlob().catch((error) => console.log(error))
        const formData = new FormData()
        formData.append('pdf', blob)

        yield getEnv(self).client.post(
          `generic_analysis_results/${self.testResults.id}/send_prevention_plan_report/`,
          formData,
          {
            headers: {
              'Content-Type': `multipart/form-data`,
            },
          },
        )
        self.setModal(ModalType.success)
      } catch (error) {
        self.setModal(ModalType.sendError)
      } finally {
        self.removeLoading('sendReport')
      }
    }),
    setAutoValidate(id) {
      self.testSelectedForValidate = id
    },
    setModal(modalType) {
      self.modalType = modalType
    },
    reload() {
      self.modalType = null
      self.loadDependencies()
    },
    resend() {
      self.modalType = null
      self.sendReport()
    },
    onError() {
      self.setModal(ModalType.genericError)
    },
    ...actionsAnalysis(self),
    ...actionsCampaign(self),
  }))
