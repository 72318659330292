import { useRouteState } from '@shared/utils'
import {
  GenericAnalysis,
  PDFPreview,
  useLayoutContext,
} from '@sr/routes/common'
import { AnalysisRouteState } from './state'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { VALIDATE_ALL } from './enums'
import { ModalType, useAnalysisModal } from './hooks'

type OnChangePageParams = {
  activePage: React.ReactElement
  prevPage: React.ReactElement
}

type SelectParams = {
  page: number
  id: string
}

type AnalysisProps = {
  personId: string
  reportType: number
}

export const Analysis: FC<AnalysisProps> = observer(
  ({ personId, reportType }) => {
    const { testId } = useParams()
    const { t } = useTranslation()
    const {
      isLoading,
      typeList,
      setAutoValidate,
      modalType,
      reload,
      resend,
      test,
      setModal,
      person,
    } = useRouteState(AnalysisRouteState, {
      personId,
      reportType,
      testId,
    })

    const [isCompleting, setIsCompleting] = useState(false)
    const [activePageNumber, setActivePageNumber]: [
      number,
      Dispatch<SetStateAction<number>>
    ] = useState(0)

    const { setIsLoading } = useLayoutContext()

    const onSelectTest = ({ page, id }: SelectParams) => {
      setAutoValidate(id)
      setActivePageNumber(page)
    }

    const onPreviewError = () => {
      setAutoValidate(VALIDATE_ALL)
      setActivePageNumber(0)
    }

    const onChangePage = ({ activePage }: OnChangePageParams) => {
      setActivePageNumber(activePage?.props?.testOrder)
    }

    const onClose = () => {
      setModal(null)
      setIsCompleting(false)
    }

    const Modal = useAnalysisModal({
      modalType,
      reload,
      resend,
      onClose,
      fullName: person?.fullName,
    })

    const generateReport = () => {
      setIsCompleting(true)
      setModal(ModalType.confirmation)
    }

    useEffect(() => {
      setIsLoading(isLoading)
    }, [isLoading])

    return (
      <>
        <GenericAnalysis
          testList={typeList}
          activePageNumber={activePageNumber}
          onChangePage={onChangePage}
          onComplete={test?.isFilled && generateReport}
        >
          <PDFPreview
            name={t('analysis:end.title')}
            onSelectTest={onSelectTest}
            onPreviewError={onPreviewError}
            testOrder={typeList?.length}
            isCompleting={isCompleting || isLoading}
            reportType={reportType}
          />
        </GenericAnalysis>
        {modalType && Modal}
      </>
    )
  }
)
