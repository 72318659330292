import { useRouteState } from '@shared/utils'
import { FC, useEffect, useMemo } from 'react'
import { PDFPreviewRouteState } from './state'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ReportPdf } from '@shared/ui'
import { ErrorList } from './components'
import { PDFViewer } from '@react-pdf/renderer'
import './styles.scss'
import { SRErrorModal } from '@sr/ui'
import { ROUTE_PATH_CAMPAIGN } from 'apps/social-referent/src/routes/enums'

type TestParams = {
  page: number
  id: string
}

type Props = {
  name: string
  onSelectTest: (testParams: TestParams) => void
  onPreviewError: () => void
  testOrder: string
  isCompleting: boolean
  reportType: number
}

export const PDFPreview: FC<Props> = observer(
  ({ onSelectTest, onPreviewError, isCompleting, reportType }) => {
    const { testId } = useParams()
    const { setIsLoading } = useLayoutContext()
    const { hasError, isLoading, testList, resultsParsed } = useRouteState(
      PDFPreviewRouteState,
      {
        testId,
      },
    )

    const reloadPage = () => {
      onPreviewError()
    }

    const pdfData = useMemo(() => {
      return resultsParsed
    }, [resultsParsed])

    useEffect(() => {
      setIsLoading(isLoading)
    }, [isLoading])

    return (
      <>
        <div className="preview">
          <div className="preview__list-box">
            <ErrorList list={testList} onSelectTest={onSelectTest} />
          </div>

          <div className="preview__pdf-box">
            {isCompleting || isLoading ? (
              <div />
            ) : (
              <PDFViewer className="preview__pdf-container" showToolbar={false}>
                <ReportPdf type={reportType} results={pdfData} />
              </PDFViewer>
            )}
          </div>
        </div>
        {hasError && (
          <SRErrorModal
            onRetry={reloadPage}
            backUrl={`../${ROUTE_PATH_CAMPAIGN.userNewTest}`}
            mainMessage="analysis:errorModal.previewError"
          />
        )}
      </>
    )
  },
)
