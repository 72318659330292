import { Wizard } from '@shared/ui'
import { wizardButtonContainerClasses, wizardContainerClasses } from './styles'
import { Props } from './types'
import { FC } from 'react'

export const SRWizard: FC<Props> = ({
  children,
  onChangePage,
  onComplete,
  onExit,
  hidePrev = false,
  hideNext = false,
  timeline = false,
  disableNavigation = false,
  activePageNumber = 0,
  completeText,
}) => {
  return (
    <Wizard
      disableNavigation={disableNavigation}
      activePageNumber={activePageNumber}
      timeline={timeline}
      hidePrev={hidePrev}
      hideNext={hideNext}
      onChangePage={onChangePage}
      onComplete={onComplete}
      wizardContainerClasses={wizardContainerClasses}
      wizardButtonContainerClasses={wizardButtonContainerClasses}
      onExit={onExit}
      completeText={completeText}
    >
      {children}
    </Wizard>
  )
}
