module.exports = {
  colors: {
    'modal-overlay': 'rgba(0, 64, 57, 0.5);',
    'gray-background': '#F8F8F8',
    'dark-gray': '#333333',
    'vcx-error-red': '#BB1313',
    'vcx-dark-white': '#FCFCFC',
    'caixa-primary': '#007EAE',
    'light-grey': '#C1C1C1',
    grayLight: '#E5E5E5',
    'primary-opacity': 'rgba(0, 64, 57, 0.1)'
  },
  fontFamily: {
    montserrat: 'Montserrat',
  },
}
